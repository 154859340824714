import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../auth';
import 'materialize-css';
import { Row } from 'react-materialize';
import '../common/common.css';
import axios from 'axios';
import './Login.css';


function Login({ token, setToken }) {
	const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const navigate = useNavigate();
  const auth = useAuth();

	useEffect((auth) => {
		if (auth && auth.token) {
			navigate('/dashboard');
		}
	}, [auth]);

	const loginUser = async (credentials) => {
		try {
			const response = await axios.post('/login', credentials);

			return response.data;
		} catch(err) {
			console.error(err);
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		const loginResult = await loginUser({
			email: userName,
			password
		})

		if (loginResult.success) {
			auth.signin(loginResult.token, loginResult.user, () => {
				// Send them back to the page they tried to visit when they were
				// redirected to the login page. Use { replace: true } so we don't create
				// another entry in the history stack for the login page.  This means that
				// when they get to the protected page and click the back button, they
				// won't end up back on the login page, which is also really nice for the
				// user experience.
				navigate('/dashboard', { replace: true });
			});

		} else {
			setErrorMessage(loginResult.error);
		}

		
	} 

	return (
		<div className="container">
			<Row>
				<div className="col s12 l6 offset-l3 login-wrapper">
					<h2>Login</h2>
					<form className="col s12" onSubmit={handleSubmit}>
						<label>
							<p>Username</p>
							<input type="text" value={userName} onChange={e => setUserName(e.target.value)} />
						</label>
						<label>
							<p>Password</p>
							<input type="password" value={password} onChange={e => setPassword(e.target.value)} />
						</label>
						<div>
							<button type="submit" className="btn">Submit</button>
						</div>
						<p className="red-text lighten-1" style={{fontSize: "0.875rem"}}>{errorMessage}</p>
					</form>
				</div>
			</Row>
		</div>
	);
}

export default Login;