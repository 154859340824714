import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaChartLine, FaGift, FaHandHoldingUsd } from 'react-icons/fa';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from '../../../auth';
import { useEffect } from 'react';

function SideBar({ activeItem }) {
  const auth = useAuth();
  const navigate = useNavigate();

  const isActive = (idx) => {
    if (activeItem === idx) {
      return true;
    }

    return false;
  } 

  const handleLogout = () => {
    auth.signout(() => navigate('/login'));
  }

  return (
    <ProSidebar>
      <SidebarHeader>
        <div
          style={{
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
          }}
        >
          Welcome,<br/> {auth.user.first_name} {auth.user.last_name}
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="round" popperArrow={true}>
          {auth.user.role == 'admin' &&
            <div>
              <MenuItem icon={<FaChartLine />} active={isActive(0)}>
                Dashboard
                <Link to="/dashboard" />
              </MenuItem>
              <MenuItem icon={<FaGift />} active={isActive(1)}>
                Gift Card Panel
                <Link to="/dashboard/gift-card" />
              </MenuItem>
              <MenuItem icon={<FaHandHoldingUsd />} active={isActive(2)}>
                Fundraising
                <Link to="/dashboard/fundraising" />
              </MenuItem>
            </div>
          }
          <MenuItem icon={<FaHandHoldingUsd />} active={isActive(3)}>
            Club Management
            <Link to="/dashboard/club-management" />
          </MenuItem>
          <MenuItem onClick={() => handleLogout()}>
            Logout
          </MenuItem>
        </Menu>
      </SidebarContent>
    </ProSidebar>
  );
}

export default SideBar;