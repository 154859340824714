import React, { useState } from 'react';
import axios from 'axios';
import 'materialize-css';
import { Modal, Dropdown, Button } from 'react-materialize';
import '../public/common/common.css';
import './GiftCardPanel.css';

function CreateGiftCardModal({ relatedUsers, token, userCard, setUserCard, setGiftCards, setRelatedUsers, closeModal, isCreateModalOpen }) {
  const [sendOption, setSendOption] = useState('');
  const [existingUser, setExistingUser] = useState();
  const [newUserFirstName, setNewUserFirstName] = useState('');
  const [newUserLastName, setNewuserLastName] = useState('');
  const [newUserPhone, setNewUserPhone] = useState('');
  const [tokenAmount, setTokenAmount] = useState(0.00);

  const selectExistingUser = (user) => {
    setExistingUser(user);
  }

  const sendToken = async () => {
    let user = existingUser;

    try {
      if (sendOption == 'new') {
        user = {
          id: 0,
          first_name: newUserFirstName,
          last_name: newUserLastName,
          phone: newUserPhone
        }
      }
  
      let data = {
        option: sendOption,
        tokenAmount: tokenAmount,
        user,
        userCard
      }
  
  
      const response = await axios.post('/api/sendTokenToReceiver', data, { headers: {
        "Authorization": `Bearer ${token}`
      } });
  
      if (response.data.isSuccess) {
        console.log('success');
        setUserCard(response.data.user_card);
        setGiftCards(response.data.gift_cards);
        setRelatedUsers(response.data.related_users);
        closeModal();
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div>
      <Modal 
      id="create-gift-card-modal"
      // options={{
      // 	onCloseEnd: () => setActiveItem({})
      // }}
      open={isCreateModalOpen}
      >
        <div className="modal-content">
        <div className="row">
          <h3>Share your gift-card token to others</h3>

          <div className="col s12">
            <p className="modal-label">Who do you want to send token to?</p>
            <div className="modal-element">
              <p>
                <label onClick={() => setSendOption("existing")}>
                  <input name="send-option" type="radio" value="existing" />
                  <span>Existing person</span>
                </label>
              </p>
              {sendOption == 'existing' &&
                <div className="col s7 modal-dropdown">
                  <Dropdown
                    id="person-dropdown"
                    options={{
                      alignment: 'left',
                      autoTrigger: true,
                      closeOnClick: true,
                      constrainWidth: true,
                      container: null,
                      coverTrigger: true,
                      hover: false,
                      inDuration: 150,
                      onCloseEnd: null,
                      onCloseStart: null,
                      onOpenEnd: null,
                      onOpenStart: null,
                      outDuration: 250
                    }}
                    trigger={
                      existingUser ? <Button node="button">{existingUser.first_name + ' ' + existingUser.last_name + ' - ' + existingUser.phone}</Button>
                      : <Button node="button">Select a person</Button>
                    }
                  >
                    {relatedUsers.map(user => (
                      <a href="#" onClick={() => selectExistingUser(user)}>{user.first_name} {user.last_name} - {user.phone}</a>  
                    ))}
                  </Dropdown>
                </div>
                }
            </div>
          </div>

          <div className="modal-element">
            <div className="col s12">
              <p>
                <label onClick={() => setSendOption("new")}>
                  <input name="send-option" type="radio" value="new" />
                  <span>New person</span>
                </label>
              </p>
              {sendOption == 'new' &&
                <div>
                  <div className="form-field">
                    <p className="form-label-wrapper">
                        <label for="receiver-first-name" className="form-label active">Receiver First Name</label>
                    </p>
                    <input placeholder="Please enter receiver first name" id="receiver-first-name" type="text" className="validate" required="" aria-required="true"
                      onChange={(e) => setNewUserFirstName(e.target.value)}
                    />
                  </div>
                  <div className="form-field">
                    <p className="form-label-wrapper">
                        <label for="receiver-last-name" className="form-label active">Receiver Last Name</label>
                    </p>
                    <input placeholder="Please enter receiver last name" id="receiver-last-name" type="text" className="validate" required="" aria-required="true" 
                      onChange={(e) => setNewuserLastName(e.target.value)}
                    />
                  </div>
                  <div className="form-field">
                    <p className="form-label-wrapper">
                        <label for="receiver-phone" className="form-label active">Receiver Phone</label>
                    </p>
                    <input placeholder="Please enter receiver phone" id="receiver-phone" type="text" className="validate" required="" aria-required="true" 
                      onChange={(e) => setNewUserPhone(e.target.value)}
                    />
                  </div>
                </div>
              }
            </div>
          </div>

          <div className="modal-element">
            <div className="col s12">
              <p className="modal-label">Who much you want to send?</p>
              <div className="form-field">
                <p className="form-label-wrapper">
                    <label for="token-amount" className="form-label active">Amount</label>
                </p>
                <input placeholder="Please enter amount" id="token-amount" type="number" step="0.01"
                  onChange={(e) => setTokenAmount(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="modal-element">
            <button className="btn waves-effect" type="button" onClick={() => sendToken()}>Send now</button>
          </div>

        </div>
        </div>
      </Modal>
    </div>
  );
}

export default CreateGiftCardModal;