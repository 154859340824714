import moment from 'moment';
import axios from 'axios';
import { useAuth } from '../../auth';
import M from 'materialize-css';


const ClubManagementList = ({clubs, viewDetail}) => {
  const auth = useAuth();

  const status = (val) => {
    if (val === 0) {
      return (
        <span className="status status-inactive">Inactive</span>
      );
    } else {
      return (
        <span className="status status-active">Active</span>
      );
    }
  }

  const getShareLink = async (clubCode) => {
    try {
      const response = await axios.get(`/api/v1/getEncryptedClub/${clubCode}`, {
        headers: {
          "Authorization": `Bearer ${auth.token}`
        }
      });

      let encryptedClub = response.data.encryptedData;
      let generatedLink = `${window.location.hostname}/fundraising/${encryptedClub}`;
      navigator.clipboard.writeText(generatedLink)
      M.toast({html: 'Fundraising link is copied.'})
    } catch(err) {
      alert('Unable to generate shared link.');
    }
  }

  return (
    <div>
      <div className="row">
      {clubs.length == 0 ?
        <div className="col s12">
            <p>No clubs have been setup yet. Please contact the admin for your club creation.</p>
        </div>
        :
        <div className="col s12">
            <table>
              <thead>
                <tr>
                    <th>ID</th>
                    <th>Event</th>
                    <th>Organization</th>
                    <th>Code</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Total raised</th>
                    <th>Raise goal</th>
                    <th>Status</th>
                    <th></th>
                </tr>
              </thead>
              <tbody>
                {clubs.map((club, idx) => (
                  <tr key={idx}>
                    <td>{club.id}</td>
                    <td>{club.event_name}</td>
                    <td>{club.organization}</td>
                    <td>{club.code}</td>
                    <td>{moment(club.start_date).format('MM/DD/YYYY')}</td>
                    <td>{moment(club.end_date).format('MM/DD/YYYY')}</td>
                    <td>0</td>
                    <td>200</td>
                    <td>{status(club.is_active)}</td>
                    <td>
                      <button className="btn waves-effect" onClick={() => viewDetail(club.id)}>View Detail</button>
                      <button className="btn waves-effect" onClick={() => getShareLink(club.code)}>Share</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
      }
      </div>
    </div>
  );
}

export default ClubManagementList;