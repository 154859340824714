// Dependencies
import React from 'react';
import {
  SquarePaymentsForm,
  CreditCardInput
} from 'react-square-web-payments-sdk';
import M from 'materialize-css';
import axios from 'axios';

const MyPaymentForm = ({ 
  amount, address, firstName, lastName, city, apiToken, userCard, resetData, closeModal, setUserCard
}) => {
  const { REACT_APP_SQUARE_PAYMENT_APPLICATION_ID, REACT_APP_SQUARE_LOCATION_ID } = process.env;

  return (
    <SquarePaymentsForm
      /**
       * Identifies the calling form with a verified application ID
       * generated from the Square Application Dashboard.
       */
      applicationId={REACT_APP_SQUARE_PAYMENT_APPLICATION_ID}
      /**
       * Invoked when payment form receives the result of a tokenize generation request.
       * The result will be a valid credit card or wallet token, or an error.
       */
      cardTokenizeResponseReceived={(tokenObj, buyer) => {

        let data = {
          userCard,
          amount,
          address,
          firstName,
          lastName,
          city,
          tokenObj
        }

        const rechargeGiftCard = async (data) => {
          const paymentResponse = await axios.post('/api/reChargeGiftCard', data, {
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
          });

          console.log('successfully charged');
          console.log(paymentResponse);

          // If successfully recharge gift card, we close modal and show a success toast
          if (paymentResponse.data.success) {
            // Update new balance
            setUserCard(paymentResponse.data.user_card);
            // Reset modal field
            resetData();
            // Close modal
            closeModal();
            // Show toast
            M.toast({html: 'Your gift card is charged successfully.'})
          }
        }

        rechargeGiftCard(data);
      }}
      /**
       * This function enable the Strong Customer Authentication (SCA) flow
       *
       * We strongly recommend use this function to verify the buyer and
       * reduce the chance of fraudulent transactions.
       */
      createVerificationDetails={() => {
        
        return ({
          amount: amount,
          /* collected from the buyer */
          billingContact: {
            addressLines: [address],
            familyName: lastName,
            givenName: firstName,
            countryCode: 'US',
            city: city,
          },
          currencyCode: 'USD',
          intent: 'CHARGE',
        })}
      }
      /**
       * Identifies the location of the merchant that is taking the payment.
       * Obtained from the Square Application Dashboard - Locations tab.
       */
      locationId={REACT_APP_SQUARE_LOCATION_ID}
    >
      <CreditCardInput />
    </SquarePaymentsForm>
  );
}

export default MyPaymentForm;