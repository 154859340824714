import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Fundraising.css';
import { useAuth } from '../../auth';
import './common/common.css';
import SideBar from './common/SideBar';
import ClubManagementList from './ClubManagementList';
import ClubManagementDetail from './ClubManagementDetail';

const ClubManagement = ()  => {
  const [clubs, setClubs] = useState([]);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [clubDetail, setClubDetail] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    // Set an API to pull all the clubs that belongs to the club manager
    const fetchClubs = async () => {
      try {
          const response = await axios.get('/api/clubs/' + auth.user.id, {
              headers: {
                  "Authorization": `Bearer ${auth.token}`
              }
          });

          setIsAuthorized(true);
          setClubs(response.data.clubs);
      } catch(err) {
          if (err.response.status === 500) {
              setIsAuthorized(false);
          } 
      }
    }

    fetchClubs();
  }, []);

  const viewDetail = async (clubId) => {
    try {
      const response = await axios.get('/api/clubs/' + auth.user.id + '/' + clubId, {
          headers: {
              "Authorization": `Bearer ${auth.token}`
          }
      });

      setClubDetail(response.data.club);
      setShowDetail(true);
    } catch(err) {
      if (err.response.status === 500) {
          // setIsAuthorized(false);
      } 
    }
  }

  const clickBack = () => {
    setShowDetail(false);
  }

  return (
    <div id="club-management-container" className="cms-container">
      <SideBar activeItem={3} />
      <div className="container">
        <div className="row">
          <h1>Club Management</h1>
        </div>
        {!isAuthorized ? 
          <div><p>You are unauthorized to use this.</p></div>
          :
          <div>
            {!showDetail ? 
              <ClubManagementList clubs={clubs} viewDetail={viewDetail} />
              :
              <ClubManagementDetail clubOrderData={clubDetail} clickBack={clickBack} />
            }
          </div>
        }
      </div>
    </div>
  )

}

export default ClubManagement;