import React, { useState, useEffect } from 'react';
import 'materialize-css';
import { Row } from 'react-materialize';
import axios from 'axios';
import '../public/common/common.css';
import './GiftCardPanel.css';
import { useAuth } from '../../auth';
import GiftCardModal from './GiftCardModal';
import CreateGiftCardModal from './CreateGiftCardModal';
import SideBar from './common/SideBar';
import './common/common.css';


function GiftCardPanel({ token }) {
	const [userCard, setUserCard] = useState({});
	const [giftCards, setGiftCards] = useState([]);
	const [relatedUsers, setRelatedUsers] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const auth = useAuth();
	const [isAuthorized, setIsAuthorized] = useState(false);

	useEffect(() => {
		const fetchGiftCards = async () => {
			try {
					const response = await axios.get('/api/myGiftCards', { headers: {
						"Authorization": `Bearer ${auth.token}`
					} });

					setIsAuthorized(true);

					setUserCard(response.data.user_card);
					setGiftCards(response.data.gift_cards);
					setRelatedUsers(response.data.related_users);
			} catch(err) {
					// Handle Unauthorized error
					if (err.response.status == 500) {
						setIsAuthorized(false);
					}
			}
		}

		fetchGiftCards();
	}, []);

	const status = (val) => {
		if (val === 0) {
			return (
				<span className="status status-not-send">Created</span>
			);
		} else if (val === 1) {
			return (
				<span className="status status-not-activated">WAITING FOR ACTIVATION</span>
			);
		} else {
			return (
				<span className="status status-active">Active</span>
			);
		}
	}

	const sendCardToReceiver = async (cardId, idx) => {
		try {
			const data = { cardId };
			const response = await axios.post('/api/sendCardToCustomer', data, { 
				headers: {
					"Authorization": `Bearer ${auth.token}`
				} 
			});

			let updatedGiftCards = [...giftCards];
			updatedGiftCards[idx]['status'] = response.data.status ?? 0;
			setGiftCards(updatedGiftCards);

		} catch(err) {
				console.error(err);
		}
	}

	const deactivateCard = async (cardId, idx) => {
		try {
			const data = { cardId };
			const response = await axios.post('/api/deactivateGiftCard', data, { headers: {
				"Authorization": `Bearer ${auth.token}`
			} });

			let updatedGiftCards = [...giftCards];
			updatedGiftCards[idx]['status'] = response.data.status ?? 0;
			setGiftCards(updatedGiftCards);

		} catch(err) {
				console.error(err);
		}
	}

	const closeModal = () => {
		console.log('close modal')
		setIsModalOpen(false);
		console.log(isModalOpen);
	}

	return (
		<div id="dashboard">
				<SideBar activeItem={1} />
				<div className="container">
					<div className="row">
						<div id="panel">
							<h1>Gift Card Panel</h1>

							{!isAuthorized ? 
								<div><p>You are unauthorized to use this.</p></div>
							:
							<div>
								<GiftCardModal 
									apiToken={auth.token}
									userCard={userCard}
									isModalOpen={isModalOpen}
									closeModal={closeModal}
									setUserCard={setUserCard}
								/>
								<CreateGiftCardModal 
									relatedUsers={relatedUsers} 
									token={auth.token} 
									userCard={userCard} 
									setUserCard={setUserCard}
									setGiftCards={setGiftCards}
									setRelatedUsers={setRelatedUsers}
									isCreateModalOpen={isCreateModalOpen}
									closeModal={closeModal}
								/>

								<div id="balance-container">
									{userCard.amount ?
										<div>
											<h5>Your current balance: {userCard.amount.toFixed(2)}</h5>
											<div id="option-container">
												<a className="modal-trigger" href="#gift-card-modal" node="button" onClick={() => setIsModalOpen(true)}>
													<button className="btn waves-effect primary-btn">Reload balance</button>
												</a>
												<a className="modal-trigger" href="#create-gift-card-modal" node="button">
													<button className="btn waves-effect transparent-btn">Create giftcard</button>
												</a>
											</div>
										</div>
										:
										<div>
											<h5>You current have no gift-card balance.</h5>
											<a className="modal-trigger" href="#gift-card-modal" node="button" onClick={() => setIsCreateModalOpen(true)}>
													<button className="btn waves-effect">Charge your giftcard</button>
												</a>
										</div>
									}
								</div>
									
								{giftCards.length > 0 ?
									<Row>
											<div className="col-12">
													<table>
															<thead>
																	<tr>
																			<th>ID</th>
																			<th>Code</th>
																			<th>Amount</th>
																			<th>Receiver Name</th>
																			<th>Receiver Phone</th>
																			<th>Status</th>
																			<th>Options</th>
																	</tr>
															</thead>
															<tbody>
																{giftCards.map( (card,idx) => (
																	<tr key={idx}>
																		<td>{card.id}</td>
																		<td>{card.code}</td>
																		<td>{`$${card.amount.toFixed(2)}`}</td>
																		<td>{card.first_name} {card.last_name}</td>
																		<td>{card.phone}</td>
																		<td>
																			{status(card.status)}
																		</td>
																		<td>
																			{card.status === 0 ? 
																				<button className="btn waves-effect waves-light send-card-btn primary-btn" type="button" onClick={() => sendCardToReceiver(card.id, idx)}>Send card</button>
																			: card.status === 1 ?
																			<div className="btn-container">
																				<button className="btn waves-effect waves-light send-card-btn primary-btn" type="button" onClick={() => sendCardToReceiver(card.id, idx)}>Re-send card</button>
																				<button className="btn waves-effect waves-light deactivate-btn transparent-error-btn" type="button" onClick={() => deactivateCard(card.id, idx)}>Deactivate</button>
																			</div>
																			: 
																			''}
																		</td>
																	</tr>
																))}
															</tbody>
													</table>
											</div>				
									</Row>
								:
									<div>
										<p>You currently do not share your gift card token with anyone.</p>
										<p>If you want to send your token to anyone, select "CREATE GIFTCARD".</p>
									</div>
								}
							</div>
						}
						</div>
					</div>
				</div>
				
		</div>
	);
}

export default GiftCardPanel;