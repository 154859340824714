import React, { useState, useEffect } from 'react';
import Header from './Header';
import MenuList from './MenuList';
import SignUpSection from './SignUpSection';


function Home() {
  const [cart, setCart] = useState([]);

	useEffect(() => {
		// fetch cart
		let currentCart = localStorage.getItem('snowboba_cart');
		if (currentCart) {
			currentCart = JSON.parse(currentCart);
			setCart(currentCart);
		}
	}, []);

  const setCartItems = (item) => {
    setCart(item);
    localStorage.setItem("snowboba_cart", JSON.stringify(item));
  }

  return (
      <div className="App">
        <Header cart={cart} />
        <MenuList cart={cart} setCartItems={setCartItems} />
        <SignUpSection />
      </div>
  );
}

export default Home;