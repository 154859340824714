import React, { useState } from 'react';
import 'materialize-css';
import { Row } from 'react-materialize';
import axios from 'axios';
import { Element } from 'react-scroll';
import './SignUpSection.css';

function SignUpSection() {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");

	const signup = async () => {
		if (firstName !== "" && lastName !== "" && email !== "") {
			setError("");

			axios.post('/api/signup', {
				first_name: firstName,
				last_name: lastName,
				email: email
			})
			.then(function (response) {
				if (response.data.status === 'success') {
					setSuccess("Thank you for signing up! Stay tune for our latest updates!");
				} else {
					setError(response.data.error);
				}
			})
			.catch(function (error) {
				console.error(error);
			});

		} else {
			setError("All information are required to sign up. Please fill in your information.");
		}
	}

	return (
		<Element name="SignUpSection">
			<div id="signup-section" className="gray-bg">
				<div id="signup-container" className="container centered">
					<h2>JOIN THE SNOW BOBA FAMILY</h2>
					{success === "" ?
						<form>
							<p id="signup-text">Sign up to receive our latest news and exclusive promotions!</p>
							<Row>
								<div className="form-group input-field col s12 l3">
									<input className="form-control" name="first_name" type="text"
										placeholder="First name" value={firstName} onChange={e => setFirstName(e.target.value)} />
								</div>
								<div className="form-group input-field col s12 l3">
									<input className="form-control" name="last_name" type="text"
										placeholder="Last name" value={lastName} onChange={e => setLastName(e.target.value)} />
								</div>
								<div className="form-group input-field col s12 l4">
									<input className="form-control" name="email" type="email"
										placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
								</div>
								<div className="form-group input-field col s12 l2">
									<button id="signup-btn" className="btn waves-effect waves-light" type="button" onClick={() => signup()}>JOIN US</button>
								</div>
								<div className="col s12">
									<p id="signup-error">{error}</p>
								</div>
							</Row>
						</form>
					: <p>{success}</p>}
				</div>
			</div>
		</Element>
	);
}

export default SignUpSection;