import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        // const userToken = JSON.parse(tokenString);
        // return userToken?.token;
        return tokenString;
    }

    const getUser = () => {
        const userString = localStorage.getItem('user');
        const user = JSON.parse(userString);
        // return userToken?.role;
        return user;
    }

    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());

    const saveToken = (userToken) =>  {
        if (userToken) {
            localStorage.setItem('token', userToken);
            setToken(userToken);
        }
    }

    const saveUser = (user) =>  {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
            setUser(user);
        }
    }

    return {
        token,
        setToken: saveToken,
        user,
        setUser: saveUser
    }
}