import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import 'materialize-css';
import { Button } from 'react-materialize';
import '../common/common.css';
import './Header.css';


function Header({cart}) {

	const scrollToSection = () => {
		scroller.scrollTo("SignUpSection", {
		  duration: 800,
		  delay: 0,
		  smooth: "easeInOutQuart"
		});
	};

	return (
		<div className="header">
			<div className="container">
				<div className="header-nav">
					<div className="header-nav-left">
						<p><Link to="#menu">MENU</Link></p>
					</div>
					<div className="header-nav-right">
						<div id="header-shopping-cart-wrapper">
							<div id="cart-btn" onClick={() => {window.location.href = "/checkout"}}>
								<img id="header-shopping-cart-img" src="/images/cart-shopping.svg" alt="cart icon" />
								<span id="cart-count">{cart.length}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="header-content">
				<div className="centered">
					<h2>Welcome to</h2>
					<img id="logo-img" src="/images/logo.png" alt="Snowboba logo" />
					<h1>SnowBoba</h1>

					<Button
						id="signup-header-btn"
						node="button"
						waves="light"
						onClick={() => scrollToSection()}
					>
						Join Us Now
					</Button>
				</div>
			</div>
		</div>
	);
}

export default Header;


