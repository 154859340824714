import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import './Fundraising.css';
import { useAuth } from '../../auth';
import SideBar from './common/SideBar';

const ClubManagement = ({clubOrderData, clickBack})  => {
  const [raisedAmount, setRaisedAmount] = useState(0);

  useEffect(() => {
    calculateRaisedAmount(clubOrderData);
  }, []);

  const calculateRaisedAmount = (clubOrderData) => {
    if (clubOrderData && clubOrderData.length > 0) {
      let totalAmount = parseFloat(0);

      for (let order of clubOrderData) {
        totalAmount += order.subtotal_amount;
      }

      if (totalAmount) {
        setRaisedAmount(totalAmount);
      }
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col s12">
          <button className="btn waves-effect" onClick={() => clickBack()}>Back</button>
        </div>
        <div className="col s12">
          <p>Total raised: <b>${parseFloat(raisedAmount).toFixed(2) || raisedAmount}</b></p>
          <table>
            <thead>
              <tr>
                  <th>ID</th>
                  <th>Total Amount</th>
                  <th>Created At</th>
                  <th>Fundraising Code</th>
              </tr>
            </thead>
            <tbody>
              {clubOrderData.map((order, idx) => (
                <tr key={idx}>
                  <td>{order.id}</td>
                  <td>${order.subtotal_amount.toFixed(2)}</td>
                  <td>{moment(order.created_at).format('MM/DD/YYYY')}</td>
                  <td>{order.fundraising_code}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )

}

export default ClubManagement;