import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import './PublicFundraising.css';

function PublicFundraising(props) {
  const { encryptedCode } = useParams();
  const [clubData, setClubData] = useState({});
  const [fundraisingData, setFundraisingData] = useState([]);
  const [errorText, setErrorText] = useState('');
  const [raisedAmount, setRaisedAmount] = useState(0);
  
  useEffect(() => {
    fetchFundraising();
  }, []);

  // fetch club data based on the encrypted code
  const fetchFundraising = async () => {
    try {
        const response = await axios.get('/api/public/fundraising/' + encryptedCode);

        setClubData(response.data.clubData);
        setFundraisingData(response.data.fundraising);
        calculateRaisedAmount(response.data.fundraising);
        setErrorText('');
    } catch(err) {
      setErrorText('Unable to load your fundraising. Please contact SnowBoba admin for more information.');
    }
  } 

  const calculateRaisedAmount = (clubOrderData) => {
    if (clubOrderData && clubOrderData.length > 0) {
      let totalAmount = parseFloat(0);

      for (let order of clubOrderData) {
        totalAmount += order.subtotal_amount;
      }

      if (totalAmount) {
        setRaisedAmount(totalAmount);
      }
    }
  }

  return (
      <div>
        <div className="container">
          <div className="centered">
            <img id="logo-img" src="/images/logo.png" alt="Snowboba logo" />
            <h3 id="club-header">Club Management</h3>
          </div>
          {errorText !== '' && 
            <h5>{errorText}</h5>
          }
          {clubData &&
            (
              <div>
                 <h5>Fundraising Event: <b>{clubData.event_name}</b></h5>
                <h5>Club Code: <b>{clubData.code}</b></h5>
                <p>Total raised: <b>${parseFloat(raisedAmount).toFixed(2)}</b></p>
              </div>
            )
          }

          {(fundraisingData && fundraisingData.length > 0) ? 
            <div className="col s12">
              <table>
                <thead>
                  <tr>
                      <th>ID</th>
                      <th>Drink Order Amount</th>
                      <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {fundraisingData.map((order, idx) => (
                    <tr key={idx}>
                      <td>{order.id}</td>
                      <td>${order.subtotal_amount.toFixed(2)}</td>
                      <td>{moment(order.created_at).format('MM/DD/YYYY')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            :
            <div>
              <p>You don't have any data yet.</p>
            </div>
          }
        </div>
      </div>
  );
}

export default PublicFundraising;