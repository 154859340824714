import React from 'react';
import { Navigate } from 'react-router-dom';
import useToken from './components/public/common/useToken';

const loginAuthProvider = {
  isAuthenticated: false,
  signin(callback) {
    loginAuthProvider.isAuthenticated = true;
    // setTimeout(callback, 100); // fake async
    callback();
  },
  signout(callback) {
    loginAuthProvider.isAuthenticated = false;
    // setTimeout(callback, 100);
    callback();
  }
};

let AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {
  let { token, setToken } = useToken();
  let { user, setUser } = useToken();

  let signin = (newToken, newUser, callback) => {
    return loginAuthProvider.signin(() => {
      setToken(newToken);
      setUser(newUser);
      callback();
    });
  };

  let signout = (callback) => {
    return loginAuthProvider.signout(() => {
      localStorage.removeItem('token');
      setToken(null);
      localStorage.removeItem('user');
      setUser(null);
      callback();
    });
  };

  let value = { token, user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useAuth = () => {
  return React.useContext(AuthContext);
}

const RequireAuth = ({ children }) => {
  let auth = useAuth();

  if (!auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" />;
  }

  return children;
}


export { loginAuthProvider, AuthProvider, useAuth, RequireAuth };