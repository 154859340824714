import React, { useState } from 'react';
import 'materialize-css';
import { Modal } from 'react-materialize';
import '../public/common/common.css';
import MyPaymentForm from './MyPaymentForm';


function GiftCardModal({ apiToken, userCard, isModalOpen, closeModal, setUserCard }) {
  const [amount, setAmount]       = useState(0);
  const [address, setAddress]     = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName]   = useState('');
  const [city, setCity]           = useState('');

  const resetData = () => {
    setAmount(0);
    setAddress('');
    setFirstName('');
    setLastName('');
    setCity('');
  }

  const setHeaderText = () => {
    if (Object.keys(userCard).length === 0) {
      return (
        <h3>Charge your card balance</h3>
      );
    } else {
      return (
        <h3>Reload your card balance</h3>
      )
    }
  }

  return (
    <div>
      <Modal 
      id="gift-card-modal"
      open={isModalOpen}
      >
        <div className="modal-content">
          <div className="container">
            <div className="row">
              {setHeaderText}

              <div className="input-field col s7">
                <input type="number" value={amount} onChange={e => setAmount(e.target.value)} />
                <label for="amount">Amount</label>
              </div>
              <div className="input-field col s6">
                <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
                <label for="firstName">First Name</label>
              </div>
              <div className="input-field col s6">
                <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
                <label for="lastName">Last Name</label>
              </div>
              <div className="input-field col s6">
                <input type="text" value={address} onChange={e => setAddress(e.target.value)} />
                <label for="address">Address</label>
              </div>
              <div className="input-field col s6">
                <input type="text" value={city} onChange={e => setCity(e.target.value)} />
                <label for="city">City</label>
              </div>
            </div>

            <MyPaymentForm 
              amount={amount}
              firstName={firstName}
              lastName={lastName}
              address={address}
              city={city}
              apiToken={apiToken}
              userCard={userCard}
              resetData={resetData}
              closeModal={closeModal}
              setUserCard={setUserCard}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default GiftCardModal;