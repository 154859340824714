import React from 'react';
import 'materialize-css';
import '../public/common/common.css';
import SideBar from './common/SideBar';
import './common/common.css';


function Dashboard() {

    return (
        <div id="dashboard">
            <SideBar activeItem={0} />
            <div className="container">
                <div className="row">
                    <h1>Dashboard</h1>
                </div>
            </div>
            
        </div>
    );
}

export default Dashboard;