import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'materialize-css';
import { Row, Col, Modal } from 'react-materialize';
import axios from 'axios';
import './MenuList.css';
import './Modal.css';


function MenuList({cart, setCartItems}) {
	const [menuList, setMenuList] = useState([]);
	const [activeCategory, setActiveCategory] = useState(0);
	const [activeItem, setActiveItem] = useState({});
	const [quantity, setQuantity] = useState(1);
	const [activeToppings, setActiveToppings] = useState([]);
	const [activeToppingsArr, setActiveToppingsArr] = useState([]);
	const [activeSweetLevel, setActiveSweetLevel] = useState({name: "Regular sweet", price: 0});
	const [activeIceLevel, setActiveIceLevel] = useState({name: "Regular ice", price: 0});

	useEffect(() => {
			const fetchData = async () => {
					try {
							const response = await axios.get('/api/getCategories');
							setMenuList(response.data);
					} catch(err) {
							console.error(err);
					}
			}

			fetchData();
	}, []);

	const setModalItem = (item) => {
		setActiveItem(item);
		if (item.toppings) {
			setActiveToppings(Array(item.toppings.length).fill(false));
			setActiveToppingsArr([]);
			setQuantity(1);
		}
		
		if (item.sweet_level) {
			setActiveSweetLevel(item.sweet_level[0]);
		}
		if (item.ice_level) {
			setActiveIceLevel(item.ice_level[0]);
		}
	}

	const setActiveTopping = (idx) => {
		let toppings = [];
		let toppingsArr = [];

		activeToppings.forEach( (topping, idx1) => {
			if (idx1 === idx) {
				toppings.push(!topping);
			} else {
				toppings.push(topping);
			}
		})

		toppings.forEach( (topping, idx1) => {
			if (topping) {
				toppingsArr.push(activeItem.toppings[idx1]);
			}
		})

		setActiveToppings(toppings);
		setActiveToppingsArr(toppingsArr);
	}

	const decreaseQty = () => {
		if (quantity > 1) {
			setQuantity(quantity-1);
		}
	}

	const updateCart = () => {

		let newCartItem = {
			id: activeItem.id,
			name: activeItem.product_name,
			quantity: quantity,
			price: activeItem.price,
			toppings: activeToppingsArr,
			sweetnessLevel: activeSweetLevel,
			iceLevel: activeIceLevel
		}
		let tmp = [...cart];

		tmp.push(newCartItem);
		setCartItems(tmp);
		resetCart();
	}

	const resetCart = () => {
		setActiveItem({});
		setQuantity(1);
		setActiveToppings([]);
		setActiveToppingsArr([]);
		setActiveSweetLevel({name: "Regular sweet", price: 0});
		setActiveIceLevel({name: "Regular ice", price: 0});
	}

	return (
			<div id="menu-section" className="gray-bg">
				<div className="container">
					<h1 id="title" className="col s6 offset-s3">Menu</h1>
					<div id="menu">
							<div id="menu-category-container" className="col s12">
									<div id="menu-category-wrapper">     
											{menuList.map( (category,idx) => (
												<button key={idx} onClick={() => setActiveCategory(idx)} className={activeCategory === idx ? 'menu-category-box active' : 'menu-category-box'}>
													<div>
														<img src={category.category_image} alt="{category.category_name} logo" />
														<p>{category.category_name}</p>
													</div>
												</button>
											))}
									</div>
							</div>

							{menuList.map( (category,idx) => (     
								<div className={activeCategory === idx ? 'menu-category-products' : 'menu-category-products hidden'}>
									<Row>
											<Col s="12" className={category['category_name'].replace(/\s/g, '')}>
													<Row className="product-row">
														<div>
															{category['product_set'].map( (item,idx1) => (
																	<div key={item['id']} className="col s6 m6 l4 xl4 product-wrapper">
																		<a className="modal-trigger" href="#item-modal" node="button" onClick={() => setModalItem(item)}>
																			<div className="product-detail-wrapper">
																					<div className="product-img-wrapper">
																						<img className="product-img" src={item['image']} alt={item['product_name']} />
																					</div>
																					<div className="product-item-wrapper">
																						<p className="product-name">{item['product_name']}</p>
																						<p className="product-price">${item['price'].toFixed(2)}</p>
																					</div>
																			</div>
																		</a>
																	</div>
															))}
														</div>
													</Row>
											</Col>
									</Row>
								</div>
							))}
					</div>
				</div>

				<Modal 
					id="item-modal"
					options={{
						onCloseEnd: () => setActiveItem({})
					}}
				>
					<div className="modal-top-nav">
						<div className="modal-top-nav-left">
							<span className="modal-back-btn modal-close">&#8592;</span>
						</div>
						<div className="modal-top-nav-right">
							<div id="shopping-cart-wrapper">
								<Link to="/checkout">
									<img id="shopping-cart-img" src="/images/cart-shopping.svg" alt="Shopping cart icon" />
									<span id="modal-cart-count">{cart.length}</span>
								</Link>
							</div>
						</div>
					</div>
					<div className="modal-content">
						<div className="container">
							<div className="item-detail-wrapper">
								<h4 id="item-name">{activeItem.product_name}</h4>
								<h5 id="item-price">${activeItem.price && activeItem.price.toFixed(2)}</h5>
								<div id="item-img-wrapper">
									<img id="item-img" src={activeItem.image} alt="item screenshot" />
								</div>
							</div>
							<div className="item-modal-container">
								<h5>Quantity</h5>
								<button id="decrease-btn" className="btn waves-effect waves-light quantity-btn orange-btn" onClick={() => decreaseQty()}>-</button>
								<span id="quantity">{quantity}</span>
								<button id="increase-btn" className="btn waves-effect waves-light quantity-btn orange-btn" onClick={() => setQuantity(quantity+1)}>+</button>
							</div>
							{activeItem && activeItem.toppings &&
								<div id="drink-addons">
									<div className="item-modal-container">
										<h5>Toppings</h5>
										{activeItem.toppings.map( (topping,idx) => (
											<p>
												<label>
													<input name="topping" type="checkbox" value={topping.name} onClick={() => setActiveTopping(idx)}/>
													<span>{topping.name} (${topping.price.toFixed(2)})</span>
												</label>
											</p>
										))}
									</div>
								</div>
							}
							<div id="drink-taste">
								{activeItem.sweet_level &&
									<div className="item-modal-container">
										<h5>Sweet Level</h5>
										{activeItem.sweet_level.map(level => (
											<p>
												<label onClick={() => setActiveSweetLevel(level)}>
													<input name="sweet-level" type="radio" value={level.name} />
													<span>{level.name}</span>
												</label>
											</p>
										))}
									</div>
								}

								{activeItem.ice_level &&
									<div className="item-modal-container">
										<h5>Ice Level</h5>
										{activeItem.ice_level.map(level => (
											<p>
												<label onClick={() => setActiveIceLevel(level)}>
													<input name="ice-level" type="radio" value={level.name} />
													<span>{level.name}</span>
												</label>
											</p>
										))}
									</div>
								}
							</div>
						</div>
					</div>
					<div className="container">
						<button id="add-btn" className="btn-large waves-effect waves-light modal-close" onClick={() => updateCart()}>Add to cart</button>
					</div>
				</Modal>
			</div>
	);
}

export default MenuList;