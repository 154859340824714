import React, { useState } from 'react';
import 'materialize-css';
import { Modal, DatePicker } from 'react-materialize';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import '../public/common/common.css';
import { useAuth } from '../../auth';


function FundraisingModal({ isModalOpen, closeModal, setFundraising }) {
  const [event, setEvent]                   = useState('');
  const [showEventError, setShowEventError] = useState(false);
  const [organization, setOrganization]     = useState('');
  const [showOrgError, setShowOrgError]     = useState(false);
  const [startDate, setStartDate]           = useState((moment(new Date()).format('MM/DD/YYYY')));
  const [endDate, setEndDate]               = useState((moment(new Date()).format('MM/DD/YYYY')));
  const [isActive, setIsActive]             = useState(1);
  const auth = useAuth();

  const handleSubmit = async () => {
    if (validateFields()) {
      try {
        let data = {
          event, 
          organization,
          startDate,
          endDate,
          isActive
        }
  
        const response = await axios.post('/api/createFundraising', data, {
          headers: {
            'Authorization': `Bearer ${auth.token}`
          }
        });
  
        if (response.data.success) {
          setFundraising(response.data.fundraising);
          resetFields();
          closeModal();
        }
  
      } catch (err) {
        console.error(err);
      }
    }
  }

  /**
   * Fields validating before submitting
   */
  const validateFields = () => {
    if (event == '') {
      setShowEventError(true);
      return false;
    }

    if (organization == '') {
      setShowOrgError(true);
      return false;
    }

    return true;
  }

  /**
   * Reset all the fields before closing the modal
   */
  const resetFields = () => {
    setEvent('');
    setShowEventError(false);
    setOrganization('');
    setShowOrgError(false);
    setStartDate((moment(new Date()).format('MM/DD/YYYY')));
    setEndDate((moment(new Date()).format('MM/DD/YYYY')));
    setIsActive(true);
  }

  return (
    <div>
      <Modal 
      id="fundraising-modal"
      open={isModalOpen}
      >
        <div className="modal-content">
          <div className="container">
            <div className="row">
              <h3>Setup fundraising event</h3>
              <form onSubmit={() => handleSubmit()}>
                <div className="input-field col s12">
                  <input type="text" value={event} onChange={e => setEvent(e.target.value)} />
                  <label for="event">Event Name</label>
                  {showEventError && <small className="error">This field is required.</small>}
                </div>
                <div className="input-field col s12">
                  <input type="text" value={organization} onChange={e => setOrganization(e.target.value)} />
                  <label for="organization">Organization Name</label>
                  {showOrgError && <small className="error">This field is required.</small>}
                </div>
                <div className="input-field col s6">
                  <DatePicker
                    id="startDate"
                    options={{
                      format: 'mm/dd/yyyy',
                      minDate: new Date(),
                      defaultDate: new Date()
                    }}
                    placeholder="Enter start date"
                    onChange={e => {
                      setStartDate(moment(e).format('MM/DD/YYYY'))
                    }}
                    value={startDate}
                  />
                </div>
                <div className="input-field col s6">
                  <DatePicker
                    id="endDate"
                    options={{
                      format: 'mm/dd/yyyy',
                      minDate: new Date(),
                      defaultDate: new Date()
                    }}
                    placeholder="Enter end date"
                    onChange={e => {
                      setEndDate(moment(e).format('MM/DD/YYYY'))
                    }}
                    value={endDate}
                  />
                </div>
                <div className="input-field col s12">
                  <p>Active Status</p>
                  <p>
                    <label onClick={() => setIsActive(1)}>
                      <input name="send-option" type="radio" value="1" checked={isActive} />
                      <span>Active</span>
                    </label>
                  </p>
                  <p>
                    <label onClick={() => setIsActive(0)}>
                      <input name="send-option" type="radio" value="0" />
                      <span>Inactive</span>
                    </label>
                  </p>
                </div>
                <button className="btn waves-effect" type="button" onClick={() => handleSubmit()}>Create Fundraising</button>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default FundraisingModal;