import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'materialize-css';
import '../public/common/common.css';
import './common/common.css';
import './Fundraising.css';
import { useAuth } from '../../auth';
import SideBar from './common/SideBar';
import FundraisingModal from './FundraisingModal';


function Fundraising() {
    const [fundraising, setFundraising] = useState([]);
    const [isAuthorized, setIsAuthorized] = useState(false);
		const [isModalOpen, setIsModalOpen] = useState(false);
    const auth = useAuth();

    useEffect(() => {
			const fetchFundraising = async () => {
					try {
							const response = await axios.get('/api/fundraising', {
									headers: {
											"Authorization": `Bearer ${auth.token}`
									}
							});

							setIsAuthorized(true);
							setFundraising(response.data.fundraising);
					} catch(err) {
							if (err.response.status === 500) {
									setIsAuthorized(false);
							} 
					}
			}

			fetchFundraising();
    }, []);

		const closeModal = () => {
			setIsModalOpen(false);
		}

		const status = (val) => {
			if (val === 0) {
				return (
					<span className="status status-inactive">Inactive</span>
				);
			} else {
				return (
					<span className="status status-active">Active</span>
				);
			}
		}

		const updateFundraisingStatus = async (fundraisingId, idx, isActivated=0) => {
			try {
				let data = {
					isActivated,
					fundraisingId
				}
				const response = await axios.post('/api/updateFundraisingStatus', data, {
					headers: {
							"Authorization": `Bearer ${auth.token}`
					}
				});

				if (response.data.success) {
					// Update status for selected fundraising
					let updatedItems = [...fundraising];
					updatedItems[idx].is_active = isActivated == 1 ? true : false;
					setFundraising(updatedItems);
				}
			} catch(err) {
				console.error(err);
			}
		}

    return (
        <div id="fundraising-container">
            <SideBar activeItem={2} />
            <div className="container">
                <div className="row">
                    <h1>Fundraising Management</h1>
                </div>
								{!isAuthorized ? 
									<div><p>You are unauthorized to use this.</p></div>
									:
									<div>
										<div className="row">
											<div id="option-container">
												<a className="modal-trigger" href="#fundraising-modal" node="button" onClick={() => setIsModalOpen(true)}>
													<button className="btn waves-effect primary-btn">Create Fundraising</button>
												</a>
											</div>
										</div>
										<FundraisingModal 
											isModalOpen={isModalOpen}
											closeModal={closeModal}
											setFundraising={setFundraising}
										/>
										<div className="row">
											{fundraising.length == 0 ?
												<div className="col s12">
														<p>No organizations have been setup yet. Start create one now.</p>
												</div>
												:
												<div className="col s12">
														<table>
															<thead>
																<tr>
																		<th>ID</th>
																		<th>Event</th>
																		<th>Organization</th>
																		<th>Code</th>
																		<th>Start date</th>
																		<th>End date</th>
																		<th>Club Manager</th>
																		<th>Status</th>
																		<th></th>
																</tr>
															</thead>
															<tbody>
																{fundraising.map((item,idx) => (
																	<tr key={idx}>
																			<td>{item.id}</td>
																			<td>{item.event_name}</td>
																			<td>{item.organization}</td>
																			<td>{item.code}</td>
																			<td>{moment(item.start_date).format('MM/DD/YYYY')}</td>
																			<td>{moment(item.end_date).format('MM/DD/YYYY')}</td>
																			<td>{item.club_manager}</td>
																			<td>{status(item.is_active)}</td>
																			<td>
																				{
																					!item.is_active ?
																						<button className="btn waves-effect primary-btn" onClick={() => updateFundraisingStatus(item.id, idx, 1)}>Activate</button>
																						:
																						<button className="btn waves-effect transparent-error-btn" onClick={() => updateFundraisingStatus(item.id, idx, 0)}>De-activate</button>
																				}
																			</td>
																	</tr>
																))}
															</tbody>
														</table>
												</div>
											}
										</div>
									</div>
								}
            </div>
            
        </div>
    );
}

export default Fundraising;