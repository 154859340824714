import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import 'materialize-css/dist/css/materialize.min.css';
import Home from './components/public/home/Home';
import PublicFundraising from './components/public/fundraising/PublicFundraising';
import Login from './components/public/authentication/Login';
import Dashboard from './components/cms/Dashboard';
import GiftCardPanel from './components/cms/GiftCardPanel';
import MyPaymentForm from './components/cms/MyPaymentForm';
import Fundraising from './components/cms/Fundraising';
import ClubManagement from './components/cms/ClubManagement';
import { AuthProvider, RequireAuth } from './auth';

function App() {

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="login" element={<Login />} />
          <Route exact path="fundraising/:encryptedCode" element={<PublicFundraising />} />
          <Route 
            path="dashboard" 
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            } 
          />
          <Route 
            path="dashboard/gift-card" 
            element={
              <RequireAuth>
                <GiftCardPanel />
              </RequireAuth>
            } 
          />
          <Route 
            path="dashboard/fundraising" 
            element={
              <RequireAuth>
                <Fundraising />
              </RequireAuth>
            } 
          />
          <Route 
            path="dashboard/club-management" 
            element={
              <RequireAuth>
                <ClubManagement />
              </RequireAuth>
            } 
          />
          
          <Route exact path="/form" element={<MyPaymentForm />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
